.main {
    display: none;
}

.banner{
    padding: 123px 25% 17.1rem 25%;
    height: 160px;
}

.banner .gradient {
    background: linear-gradient(90deg, rgba(0,0,0,1) 33%, rgba(25,25,25,0.4) 100%);
    position: absolute;
    top:0;
    left:0;
    height: 1000px;
    width: 100%;
    z-index: -1;
}

.banner img {
    opacity: 0.35;
    position: absolute;
    top:0;
    left:0;
    height: 1000px;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}

.banner .contents {
    position: absolute;
    left: 25%;
    right: 25%;
    transition: transform 1s ease,opacity 1s ease;
    opacity: 0;
    transform: translateY(100%);
}

.banner .intro h1 {
    font-size: 46px;
    margin:2px 0 18px 0

}

.banner .intro p {
    margin: 0;
    font-size: 24px;
}

.banner .intro p,.banner .intro h1 {
    color: #fff;
}

.banner .intro p:first-child {
    font-size: 18px;
    color: var(--primary01);
}

.banner .intro .group-button {
    display: flex;
    gap: 20px;
    margin-top: 2.5rem;
}

.banner .intro .group-button a,
.banner .intro .group-button button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    padding: 10px 0;
    width: 280px;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 19px;
}

.banner .intro .group-button a:first-child {
    background-color: var(--primary01);
    color: #fff;
    box-shadow: var(--shadow02);
    transition: .2s ease;
}

.banner .intro .group-button a:first-child:hover {
    filter: brightness(0.9);
    scale:1.02
}

.banner .intro .group-button button:nth-child(2) {
    color: #fff;
    border: 1px solid #fff;
}

.banner .intro .group-button button:nth-child(2):hover {
    filter: brightness(0.8);
}

@media only screen and (max-width: 680px)   {
    .banner {
        display: block;
        padding: 72px 0px 120px 0px;
    }

    .banner .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .banner .intro h1 {
        text-align: center;
        font-size: 40px;
    }

    .banner .intro p {
        text-align: center;
    }
    .banner .img-banner {
        width: 100%;
    }

    .banner .intro .group-button {
        flex-direction: column;
        align-items: center;
    }

    .contato {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:2rem !important
    }

    .contato form, .contato div {
        width: 100% !important;
    }
}

@media only screen and (max-width: 980px)   {

.estat {
    flex-direction: column;
}

}

.clientes {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap:10px;
    border-radius: 20px;
    box-shadow: var(--shadow01);
    position: absolute;
    top: 35rem;
    right: 25%;
    left: 25%;
    padding: 12px;
    z-index: 2;
    transition: transform 1s ease,opacity 1s ease;
    transform: translateY(80%);
}

.clientes h2{
    color: #5C5855;
    font-size: 12px;
    text-align: center;
    margin: 0;
}
.clientes .carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .clientes .carousel-wrapper .fa-angle-right,
  .clientes .carousel-wrapper .fa-angle-left {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary01);
    font-size: 22px;
    cursor: pointer;
    opacity: 0.3;
    transition: .2s;
  }
  .clientes .carousel-wrapper .fa-angle-right:active,
  .clientes  .carousel-wrapper .fa-angle-left:active{
    scale:0.85;
  }
  
  .clientes .carousel-wrapper .fa-angle-right:hover,
  .clientes  .carousel-wrapper .fa-angle-left:hover {
    opacity: 1;
  }

  .clientes .carousel-wrapper button{
    background: transparent;
    border: none;
  }
  
  .clientes .carousel-wrapper .carousel{
    width: 92%;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    align-items: center;
    border-radius: 8px;
    scroll-behavior: smooth;
    scrollbar-width: none;
    gap:120px
  }
  .clientes  .carousel::-webkit-scrollbar {
    display: none;
  }

  .visible {
    opacity: 1 !important;
    transform: translateY(0) !important;
}
.clientes  .carousel .card h2 {
    font-weight: 500;
    font-size: 12rem;
    color: #efefefe3;
  }

.clientes .slider img {
    width: 48px;
}

.clientes .slider #metro,
.clientes .slider #cptm {
    width: 34px;
}

.clientes .slider #vale {
    width: 64px;
    height: 25px;
}

.clientes .slider #samarco {
    width: 94px;
    height: 15px;
}

.clientes .slider #dpbarros,
.clientes .slider #metrorio {
    width: 58px;
}

.clientes .slider #ag {
    width: 80px;
}

.estat {
    background-color: var(--primary03);
    display: flex;
    justify-content: space-evenly;
    gap:40px;
    align-items: center;
    color: #444;
    padding: 32px;
}

.estat h2 { margin: 0;}

.estat .numeros {
    display: flex;
    justify-content: center;
    align-items:flex-start;
    gap:32px;
}

.estat .numeros div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:6px;
    width: 33%;
}

.estat .numeros div p {
    margin: 0;
}

.estat .numeros div:last-child p {
    text-align: end;
}


.estat .numeros div:nth-child(2) p {
    text-align: center;
  }

.estat span {
    background: var(--primary01);
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    padding: 2px;
}

.servicos, .portfolio, .contato {
    padding: 48px 23%;
}

.sobrenos,.sobrenos-2 {
    display: flex;
    justify-content: space-evenly;
    padding: 72px 23%;
    gap: 32px;
    transition:.3s ease;
    text-align: justify;
    text-indent: 2%;
}

.sobrenos .texto,
.sobrenos-2 .texto {
    padding: 8px 0 70px 0;
}


.sobrenos div,.sobrenos-2 div {
    width: 35%;
}

.sobrenos img,.sobrenos-2 img {
    width: 27%;
    object-fit: cover;
    border-radius: 10px;
    height: 75vh;
    object-position: 10% 4%;
    position: sticky;
    top: 120px;
    
}
.sobrenos div p:first-child {
    font-size: 18px;
    color: var(--primary01);
}

.sobrenos h3 {
    font-size: 47px;
    margin: 20px 0 30px 0;
    text-align:start;
    text-indent: 0;
}

.sobrenos p, .sobrenos-2 p {
    font-size: 21px;
}


.sobrenos-2 .texto{
    display: flex;
    flex-direction: column;
}

.sobrenos-2 .calltoact {
    color: #fff;
    width: 200px;
    height: 45px;
    border: 1px solid var(--secondary01);
    background: transparent;
    color: var(--secondary01);
    font-weight: 400;
    border-radius: 30px;
    margin: 1rem 0;
    font-size: 16px;
    cursor: pointer;
    transition: .2s ease-in;
    align-self: center;
}

.sobrenos-2 .calltoact:hover {
    background-color: var(--secondary01);
    color: #fff;
}

.servicos {
    color: var(--background); 
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    align-items: center;
    position: relative;
    left:0;
    right:0;
    transition: .6s ease;
    z-index: 1;
}

.servicos h2{
    margin: 0;
    font-size: 28px;
    }
    
    .servicos .divisor {
        height: 3px;
        width: 30%;
        background-color: var(--primary01);
        margin: 14px 0 64px 0;
        align-self: center;
    }
    
    .servicos .cards {
        margin-top:3rem;
        display: flex;
        justify-content: space-evenly;
        gap: 12px;
    }
    
    .servicos .cards .card-wrapper {
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 31rem;
    }
    
    .servicos .cards .card {
        position: relative;
        overflow: hidden;
        /* border-radius:12px; */
        display: flex;
        align-items:flex-end;
        justify-content: center;
        height: 50%;
        box-shadow: var(--shadow01);
    }
    
    .servicos .cards .card img{
        background-color: #444;
        width: 100%;
        /* border-radius: 12px 12px 0 0; */
        display: inline-block;
        overflow: clip;
    }
    
    .servicos .cards .card-wrapper h3 {
        margin: 14px 0px 0px;
        text-align: start;
        font-size: 32px;
    }
    
    .servicos .cards .card-wrapper span {
        font-size: 20px;
    }
    
    .servicos .cards .card-wrapper a {
        text-align: center;
        color: var(--background);
        margin: 7px 10px;
        background: transparent;
        border: 1px solid var(--background);;
        padding: 10px 24px;
        text-decoration: none;
        border-radius: 30px;
    }

    .servicos .cards .card-wrapper p {
        text-indent: 2%;
        line-height: 24px;
        text-align: justify;
    }

@media only screen and (max-width: 1540px)   {
    .banner {
        padding: 120px 54px 17.4rem 54px; 
    }

    .servicos {
        padding: 7.5rem 48px 2rem 48px;
    }

    .portfolio, .contato {
        padding: 48px;
    }

    .sobrenos,.sobrenos-2 {
        padding: 72px;
        gap:72px;
    }

    .banner .contents {
        left: 48px;
        right: 48px;
    }

    .clientes {
        right: 80px;
        left: 80px;
    }

    .servicos .cards .card-wrapper {
        width: 24%;
    }

}


.portfolio {
    z-index: 10000;
    transition: .6s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portfolio .calltoact {
    margin: 8rem 0 4rem;
    background: transparent;
    border: 1px solid #8f8f8f96;
    padding: 32px 48px;
    height: 24px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 18px;
    color: #c6c6c6;
    font-weight: 600;
    transition: .3s ease-in;
}

.portfolio .calltoact:hover {
    background:var(--primary01);
    color: #fff;
    border: 1px solid var(--primary01);
}

.portfolio h2 {
    color: #fff;
    margin: 0;
    margin-bottom: 32px;
}

.portfolio  .carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .portfolio  .carousel-wrapper .fa-angle-right,.carousel-wrapper .fa-angle-left {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary01);
    font-size: 32px;
    cursor: pointer;
    opacity: 0.3;
    transition: .2s;
    z-index: 10000;
  }
  .portfolio  .carousel-wrapper .fa-angle-right:active,
  .portfolio  .carousel-wrapper .fa-angle-left:active{
    scale:0.85;
  }
  
  .portfolio  .carousel-wrapper .fa-angle-right:hover,
  .portfolio  .carousel-wrapper .fa-angle-left:hover {
    opacity: 1;
  }

  .portfolio .carousel-wrapper button{
    background: transparent;
    border: none;
    z-index: 10000;
  }
  
  .portfolio .carousel-wrapper .carousel{
    width: 92%;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    scrollbar-width: none;
    gap:50px
  }
  .portfolio .carousel::-webkit-scrollbar {
    display: none;
  }
  
  .portfolio .carousel .card {
    flex: 0 0 260px;
    display: flex;
    justify-content: center;
    scroll-snap-align: start;
    height: 400px;
    background: #1a1a1a;
    cursor: pointer;
    position: relative;
    margin-bottom: 24px;
    overflow-y: auto;
    
  }

  .portfolio .carousel .card img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .portfolio .carousel .card .info {
    position:absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 6px;
    background: linear-gradient(rgba(68, 68, 68, 0.1) 0%,rgba(0, 0, 0, 0.389) 10%,#1a1a1a 60%,black 100%);
  }

  .portfolio .carousel .card .detalhe {
    opacity: 0;
    position: absolute;
    top:0;
    bottom: 0;
    background-color:#1a1a1a;
    width: 100%;
    transition: .2s;
    overflow: hidden;
  }

  .portfolio .carousel .card .detalhe p {
    padding: 20px;
    font-weight: 500;
    color: #efefefe3;
    text-indent: 2%;
    text-align: justify;
    margin: 0;
    font-size: 14px !important;
  }

  .portfolio .carousel .card:hover .detalhe {
    opacity: 1 !important;
  }
  
  .portfolio .carousel .card .info p {
    font-weight: 500;
    color: #efefefe3;
    text-align: center;
    padding: 0 20px;
    margin: 0;
  }

  .portfolio .carousel .card p:last-child {
    font-size: 10px;
    margin-bottom: 12px;
  }

  .beneficios {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 0 3rem 0;
    transition:.6s ease;
    z-index: 0;
    left:0;
    right: 0;
    position: relative;
    background-color: #fff;
  }

  .beneficios p:first-child {
    color: var(--primary01);
  }

  .beneficios h3 {
    font-size: 28px;
    margin: 4px 0 60px;
    text-align: center;
  }

  .beneficios .lista {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px
    
  }

  .beneficios .lista .row {
    display: flex;

  }

  .beneficios .lista .row div {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .beneficios .lista .row div img {
    width: 42px;
  }

  .beneficios .lista .row div p {
    text-align: center;
  }
  
  @media screen and (max-width: 900px) {
    .carousel .card {
        flex: 0 0 45% !important;
      }

    .contato {
        gap: 20px !important
    }

    .portfolio {
        padding: 4.5rem 0 24px 0;
    }
    .sobrenos img, .sobrenos-2 img {
        width: 45%;
    }

    .sobrenos div, .sobrenos-2 div {
        width: 55%;
    }
  }

  @media screen and (max-width: 600px) {
    .calltoact a {
        padding: 18px 20px !important;
    }
    
    .portfolio .carousel-wrapper .carousel{
            gap: 20px
    }

    .clientes {
        left: 24px;
        right: 24px;
    }
    
  .banner {
    padding: 120px 54px 420px 54px; 
}

.clientes {
    top: 43rem
}

.servicos .cards .card {
    height: 250px;
}

.servicos .cards .card-wrapper {
    justify-content: flex-start;
    height: 100%;
    margin-bottom: 20px;
}
}

@media screen and (max-width: 500px) {
.carousel .card {
    flex: 0 0 100% !important;
  }

  .estat .numeros {
    gap:14px
  }

  .estat span {
    font-size: 24px;
  }

  .servicos {
    padding-left: 0px;
    padding-right: 0px;
  }

  .calltoact a {
    font-size: 15px !important;
    padding: 10px !important;
  }

  .calltoact h4 {
    font-size: 14px !important;
  }

  .sobrenos,.sobrenos-2 {
    padding: 32px;
  }
}

.contato {
    display: flex;
    justify-content: space-between;
    gap: 12rem;
    padding-top: 5.5rem;
    z-index: 1;
}

.contato div p:first-child{
    color: var(--primary01);
    margin: 0;
}

.contato div p:nth-child(2) {
    font-size: 40px;
    margin: 0;
    font-weight: 700;
}

.contato form, .contato div {
    width: 50%;
}

.contato form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #D9D9D9;
    padding: 28px; 
    border-radius: 10px;
}

.contato form div {
    width: 100%;
}

.contato form label {
    color: #262626;
    display: block;
    font-size: 12px;
}

.contato form input, .contato form textarea {
    padding: 0px 8px;
    width: 100%;
    border:2px solid #D9D9D9;
    border-radius: 10px;
}

.contato form input {
    height: 40px;
    margin: 3px 0 26px 0;
}

.contato form textarea {
    height: 80px;
    margin: 3px 0 32px 0;
    resize: none;
}

.contato form button {
    border: none;
    width: 80%;
    height: 48px;
    cursor: pointer;
    border-radius: 30px;
    color: #fff;
    background: var(--background);
    font-size: 20px;
    transition: .2s;
}

.contato form button:hover {
    filter: brightness(0.8);
}

@media screen and (max-width: 820px) {
.clientes .carousel-wrapper .carousel{
    gap:24px !important;
}

.servicos .cards .card-wrapper {
    width: 50% !important;
}
}

@media screen and (max-width: 700px) {

.servicos .cards {
    flex-direction: column;
    align-items: center;
}

.servicos .cards .card-wrapper {
    width: 85% !important;
}

.sobrenos,.sobrenos-2 {
    display: block;
  }
  

  .sobrenos div, .sobrenos img,.sobrenos-2 div, .sobrenos-2 img {
    width: 100% !important;
  }

  .sobrenos img, .sobrenos-2 img {
    height: 15rem;
    }

    .sobrenos p, .sobrenos-2 p {
        font-size: 16px;
    }

    .sobrenos .texto, .sobrenos-2 .texto {
        padding: 0;
    }


  .sobrenos img, .sobrenos-2 img {
    position: static;
  }

  .beneficios .lista .row div {
    width: 120px;
  }

  .beneficios .lista .row img{
    width: 30px !important;
  }

  .beneficios .lista .row p {
    font-size: 12px;
  }

}



.calltoact {
    position: relative;
    height: 470px;
    background: var(--background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:24px;
}

.calltoact h4 {
    margin: 0;
    color: #fff;
    font-size: 20px;
    z-index: 2;
    margin: 8px;
    text-align: center;
}

.calltoact a {
    margin: 8px;
    color: #fff;
    text-decoration: none;
    background:var(--primary01);
    padding: 18px 72px;
    font-size: 21px;
    border-radius: 40px;
    box-shadow: var(--shadow02);
    z-index: 2;
    transition: .2s;
}

.calltoact a:hover {
    scale: 1.05;
}

.calltoact a:active {
    padding: 20px 72px 20px 72px;
}
.calltoact img {
    position: absolute;
    left:0;
    top:0;
    height: 470px;
    opacity: 0.4;
    width: 100%;
    object-fit:cover;
    object-position:top;
}

@media screen and (max-width: 1080px) {
    .servicos .cards .card-wrapper {
        width: 40%;
    }

    .servicos .cards .card-wrapper img {
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 360px) {
    .servicos {
        padding:4rem 0px 24px 10px;
    }

    .servicos .cards .card-wrapper .card {
        height: 10rem;
    }

    .portfolio{
        padding-top: 0rem;
    }
   
    .portfolio  .carousel-wrapper .fa-angle-right,.carousel-wrapper .fa-angle-left {
        padding: 0 !important;
    }
    .estat .numeros{
        align-items: center;
        flex-direction: column;
    }

    .beneficios .lista .row {
        flex-wrap: wrap;
        justify-content: center;
    }

    .estat .numeros div p{
    text-align: center !important;
    }
    .banner .intro .group-button a,
.banner .intro .group-button button {
width: 90vw;
justify-content: center;
display: flex;
}

.banner h1 {
    font-size: 20px !important;
}
    
    .servicos h2,
    .sobrenos h3,
    .contato p:nth-child(2),
    .beneficios h3,
    .estat h2
    {
        font-size: 15px !important;
    }

    .banner p:first-child,.servicos a,.calltoact a,.beneficios p,.contato p:first-child {
        font-size: 10px !important;
    }



    .banner p,.servicos p,.sobrenos p,.sobrenos-2 p,.contato p:nth-child(3),.contato form button{
        font-size: 14px !important;
    }
    

    .beneficios .lista .row p{
       font-size: 10px !important;
    }

    /* .sobrenos,.sobrenos-2,.contato {
        padding: 20px 0px !important;
    }

    .sobrenos img,.sobrenos-2 img{
        height: 8rem;
    } */

    .contato form{
        padding: 28px 4px;
    }
    
}

@keyframes slide {
    from{transform: translateX(0);}
    to{transform: translateX(-100%);}
}

.clientes .slider-wrapper {
    overflow: hidden;
    white-space: nowrap;
    padding: 0 32px;
}

.clientes .slider-wrapper:hover .slider {
    animation-play-state: paused;
}

.clientes .slider-wrapper .slider {
    animation:  15s slide infinite linear;
    display: inline-flex;
    align-items: center;
}

.clientes .slider-wrapper .slider div {
    margin: 0 40px;
}

