header {
    position: sticky;
    top:0;
    padding: 8px 50px;
    transition:  .4s ease;
    z-index: 3;
}
header .desktop-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
header nav {
    display: flex;
    gap: 45px;
    align-items: center;
}

header nav button ,aside nav button{
    color: #fff;
    background: transparent;
    border: none;
    transition: .1s;
    cursor: pointer;
    font-size: 15px;
}

header nav a:nth-child(4) {
    background-color: var(--primary01);
    padding: 8px 32px;
    border-radius: 48px;
    transition: .2s all ease-in-out;
    text-decoration: none;
    color: #fff;

}

header nav a:nth-child(4):hover {
    filter: brightness(0.9);
    padding: 8px 48px;
    color: #fff;
}

header .logo {
    width: 3.5rem;
    filter: brightness(10)
}

header .menu {
    background-color: transparent;
    border:none;
    display: none;
    cursor: pointer;
}

header .menu .fa-bars {
    color: var(--primary01);
    font-size: 20px;
}

aside {
    background-color: rgba(0,0,0,0.85);
    position: fixed;
    height: 100vh;
    top:0;
    right: 0;
    width: 280px;
    padding: 23px 12px;
    z-index: 6;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    transition: transform .3s ease-in-out;
    display: flex;
}

.aside-blur {
    position: absolute;
    width: 100vh;
    position: fixed;
    height: 100%;
    backdrop-filter:blur(10px) ;
    z-index: 5;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
}

.open {
    transform: translateX(100%);
}

.close {
    transform: translateX(0);
}

aside .menu {
    display: flex;
    background-color: transparent;
    border:none;
    display: none;
    cursor: pointer;
    align-self: flex-end;
    z-index: 5;
}

aside .menu .fa-xmark {
    color: var(--primary01);
    font-size: 24px;
}

aside nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

aside nav a:nth-child(4) {
    border: 1px solid #fff;
    padding: 8px 24px;
    border-radius: 48px;
    transition: .15s all ease-in-out;
    color: #fff;
    text-decoration: none;

}

aside nav a:nth-child(4):hover {
    border-color: var(--primary01);
    background-color: var(--primary01);
    color: #fff;
}

@media only screen and (max-width: 1540px) {
    header .desktop-bar {
        justify-content: space-between;
    }
}



@media only screen and (max-width: 900px) {
    header {
        padding: 6px 12px;
    }
    header .menu {
        display: block;
    }
    header .desktop-bar nav{
        display: none;
    }
}

header nav button:hover, aside nav button:hover {
    color: var(--primary01);
}

@media only screen and (max-width: 360px) {
    aside {
        width: 90vw;
    }
}