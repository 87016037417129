footer {
    padding: 24px 48px;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    color: #ffffffc8;
}

footer p{
margin: 3px 0;
font-size: 12px;
}

footer p:last-child {
    margin-top: 20px;
}

footer img{
    width: 54px;
}

footer .fa-instagram {
    color: black;
    background-color: #ffffffc8;
    padding: 4px;
    border-radius: 100%;
    height: 14px;
    width: 14px;
}

footer div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
    gap:20px
}

footer div:nth-child(2) button {
    border: none;
    background: rgb(40, 40, 40);
    cursor: pointer;
}

footer div:nth-child(2) button:hover {
    filter: brightness(0.8);
}

footer div:nth-child(2) .fa-arrow-up-long {
    color: #ffffffc8;
    padding: 9px 8px ;
    font-size: 16px;
}

footer div:nth-child(1) a {
    color: #ffffffc8;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap:3px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1540px) {
footer {
    justify-content: space-between;
}
}

@media only screen and (max-width: 480px) {
 footer {
    flex-direction: column;
    align-items: center;
    gap: 40px
 }

 footer p {
    font-size: 10px !important;
 }
}

