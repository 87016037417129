:root {
    --primary01:#fe7f2d;
    --primary02: #fcca46;
    --primary03: #FFEDE3;

    --secondary01: #619b8a;
    --secondary02:#a1c181;
    --background:#233d4d;

    --shadow01: 0px 0px 18px 4px rgba(10,10,10,0.2);
    --shadow02: 0px 0px 24px 1px rgba(255, 69, 49, 0.6);
}

body {
    font-family: sans-serif;
    margin:0;
    padding: 0;
    box-sizing:border-box;
    overflow-x: hidden;
}

input,textarea {
    box-sizing:border-box
}

input:focus-visible {
    outline:none;
}


.loading {
    background-color: rgb(19, 19, 19);
    position: absolute;
    z-index: 5;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition:transform .8s;
}


.loading img {
    width: 100px;
    margin-bottom: 12px;
}